import React, { useState } from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import { graphql } from "gatsby";
import Layout from "../components/base-structure/Layout";
import BlogBox from "../components/common/BlogBox";
import Customizable from "../components/home/Customizable";
import "../styles/CategoryPage.scss";
import Seo from "../components/common/SEO";

const Category = ({ data }) => {
  const [visible, setVisible] = useState(3);

  const readmore = () => {
    setVisible((visible) => visible + 3);
  };
  const showless = () => {
    setVisible(3);
  };

  return (
    <Layout>
      <Seo
        title={data?.strapiCategory?.MetaTitle}
        description={data?.strapiCategory?.MetaDescription}
        tag="index"
        pageUrl={`blog/${data?.strapiCategory?.CanonicalTag}/`}
      />
      <Container>
        <Box className="categoryWrapper">
          <Box className="categoryHead">
            <Typography variant="h1">{data.strapiCategory.Name}</Typography>
          </Box>
          <Box
            className="writerBlogFlex"
            sx={
              data?.allStrapiBlog?.nodes.length < 2
                ? { justifyContent: "flex-start" }
                : { justifyContent: "center" }
            }
          >
            {data?.allStrapiBlog?.nodes &&
              data?.allStrapiBlog?.nodes
                ?.slice(0, visible)
                ?.map((blog, index) => {
                  return <BlogBox blog={blog} key={index} indexProp={true} />;
                })}
          </Box>
          {data.allStrapiBlog.nodes.length > 3 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "20px 0px",
              }}
            >
              {visible >= data.allStrapiBlog.nodes.length ? (
                <Button
                  onClick={showless}
                  sx={{
                    backgroundColor: "orange",
                    color: "white",
                    padding: "10px 20px",
                    borderRadius: "50px",
                    fontFamily: "Poppins",
                    "&:hover": {
                      backgroundColor: "orange",
                      color: "white",
                    },
                  }}
                >
                  Show Less
                </Button>
              ) : (
                <Button
                  onClick={readmore}
                  sx={{
                    backgroundColor: "#611F69",
                    color: "white",
                    padding: "10px 20px",
                    borderRadius: "50px",
                    fontFamily: "Poppins",
                    "&:hover": {
                      backgroundColor: "#611F69",
                      color: "white",
                    },
                  }}
                >
                  Load More
                </Button>
              )}
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Container>
      <Customizable
        indexprop={4}
        title="Ready to sign up for an extremely
customizable experience?"
        background="#FBF7EE"
        textColor="#611F69"
        pointsColor="#611F69"
      />
    </Layout>
  );
};

export default Category;

export const query = graphql`
  query MyQuery($Slug: String) {
    strapiCategory(Slug: { eq: $Slug }) {
      Slug
      Name
      CanonicalTag
      MetaDescription
      MetaTitle
    }
    allStrapiBlog(
      sort: { fields: createdAt, order: DESC }
      filter: { categories: { elemMatch: { Slug: { eq: $Slug } } } }
    ) {
      nodes {
        Slug
        Content
        Title
        author_profile {
          Slug
          Name
        }
        id
        Image {
          url
        }
        createdAt
        categories {
          Name
          Slug
        }
      }
    }
  }
`;
